import { COMPONENT_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { WidgetDesignBuilderFn } from '../../editor-app/editor.app.types';
import { getRole } from '../../utils';

export const accessRestrictedDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t: ILocaleKeys,
): void => {
  widgetDesignBuilder.set({
    title: t.app.widgets.accessRestricted.design.title(),
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.accessRestricted.design.tabs.title(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ACCESS_RESTRICTEDS.TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.accessRestricted.design.tabs.subtitle(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ACCESS_RESTRICTEDS.SUB_TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.accessRestricted.design.tabs.mainButtons(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ACCESS_RESTRICTEDS.BACK_BUTTON].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.accessRestricted.design.tabs.loginButton(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ACCESS_RESTRICTEDS.SWITCH_ACCOUNT_BUTTON].map(
            getRole,
          ),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.accessRestricted.design.tabs.background(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ACCESS_RESTRICTEDS.CONTAINER].map(getRole),
        });
    });
};
