import { WidgetBuilder } from '@wix/app-manifest-builder';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { resetPasswordDesignBuilderFn } from './resetPassword.widget.design';
import { getRole } from '../../utils';

export const setResetPasswordWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName: t.app.widgets.displayNames.resetPassword(),
    nickname: 'resetPassword',
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.app.widgets.gfpp.changeDesign(),
      actionType: 'design',
      actionId: '',
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      actionId: CUSTOM_ACTIONS_IDS.RESET_PASSWORD_SHOW_HIDE_ELEMENTS,
    })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('hide', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('design', { behavior: 'DEFAULT' })
    .set('animation', { behavior: 'DEFAULT' });
  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,

    essential: {
      enabled: false,
    },
  });

  widgetBuilder.configureWidgetDesign((wb) =>
    resetPasswordDesignBuilderFn(wb, t),
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.CONTAINER),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.CONTAINER),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.CONTAINER),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.resetPassword.displayNames.title(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.PASSWORD_INPUT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.resetPassword.displayNames.passwordInput(),
      });
      connectedComponentsBuilder
        .panel<'TextInput', 'settings'>('settings')
        .configureControls({
          textType: {
            hidden: true,
          },
          errorMessageFormat: {
            hidden: true,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(
      COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.CONFIRM_PASSWORD_INPUT,
    ),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.resetPassword.displayNames.confirmPasswordInput(),
      });
      connectedComponentsBuilder
        .panel<'TextInput', 'settings'>('settings')
        .configureControls({
          textType: {
            hidden: true,
          },
          errorMessageFormat: {
            hidden: true,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.resetPassword.displayNames.primaryButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.resetPassword.displayNames.success.title(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.SUB_TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.resetPassword.displayNames.success.subtitle(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.PRIMARY_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.resetPassword.displayNames.primaryButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.RESET_PASSWORD.SUCCESS_STEP.SECONDARY_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.resetPassword.displayNames.success.sucondaryButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
    },
  );
};

export const configureResetPasswordViewStates = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.configureWidgetViewState(
    'createPassword',
    (widgetStateBuilder) => {
      widgetStateBuilder.title(
        t.app.widgets.resetPassword.states.createPassword(),
      );
      widgetStateBuilder.useAsInitialViewState().build();
    },
  );

  widgetBuilder.configureWidgetViewState('success', (widgetStateBuilder) => {
    widgetStateBuilder
      .title(t.app.widgets.resetPassword.states.success())
      .build();
  });

  widgetBuilder.configureWidgetViewState('error', (widgetStateBuilder) => {
    widgetStateBuilder
      .title(t.app.widgets.resetPassword.states.error())
      .build();
  });
};
