import { COMPONENT_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { WidgetDesignBuilderFn } from '../../editor-app/editor.app.types';
import { getRole } from '../../utils';

export const emailConfirmationDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t: ILocaleKeys,
): void => {
  widgetDesignBuilder.set({
    title: t.app.widgets.emailConfirmation.design.title(),
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.title(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.subtitle(),
        })
        .groups()
        .set({
          roles: [
            COMPONENT_IDS.EMAIL_CONFIRMATION.SUB_TITLE,
            COMPONENT_IDS.EMAIL_CONFIRMATION.EMAIL,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.errorMessages(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.ERROR_MESSAGE.TEXT].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.inputs(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.OTP_INPUT].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.mainButtons(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.PRIMARY_BUTTON].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.spamMessage(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TITLE].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.resendCode(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TEXT].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.background(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.CONTAINER].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.app.widgets.emailConfirmation.design.tabs.closeButton(),
        })
        .groups()
        .set({
          roles: [COMPONENT_IDS.EMAIL_CONFIRMATION.CLOSE_BUTTON].map(getRole),
        });
    });
};
