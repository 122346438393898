import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS, STATES } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { emailConfirmationDesignBuilderFn } from './emailConfirmation.widget.design';
import { getRole } from '../../utils';

export const setEmailConfirmationWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName: t.app.widgets.displayNames.emailConfirmation(),
    nickname: 'emailConfirmation',
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.app.widgets.gfpp.manageSignupSettings(),
      actionId: CUSTOM_ACTIONS_IDS.MANAGE_SIGNUP_SETTINGS,
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      actionId: CUSTOM_ACTIONS_IDS.EMAIL_CONFIRMATION_SHOW_HIDE_ELEMENTS,
    })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });
  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('hide', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('design', { behavior: 'DEFAULT' })
    .set('animation', { behavior: 'DEFAULT' });

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    essential: {
      enabled: false,
    },
  });

  widgetBuilder.configureWidgetDesign((wb) => {
    emailConfirmationDesignBuilderFn(wb, t);
    configureEmailConfirmationViewStates(widgetBuilder, t);
  });

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.SERVER_ERROR),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.emailConfirmation.displayNames.errorMessage(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.emailConfirmation.displayNames.title(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.SUB_TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.emailConfirmation.displayNames.subtitle(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.EMAIL),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.emailConfirmation.displayNames.email(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.OTP_INPUT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.emailConfirmation.displayNames.otpInput(),
      });
      connectedComponentsBuilder
        .panel<'TextInput', 'settings'>('settings')
        .configureControls({
          textType: {
            hidden: true,
          },
          errorMessageFormat: {
            hidden: true,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.PRIMARY_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.emailConfirmation.displayNames.submitButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.CLOSE_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.emailConfirmation.displayNames.closeButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TIMER),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.emailConfirmation.displayNames.resendTimer(),
      });
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.EMAIL_CONFIRMATION.RESEND_TEXT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.emailConfirmation.displayNames.resendLink(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );
};

export const configureEmailConfirmationViewStates = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.configureWidgetViewState(
    STATES.EMAIL_CONFIRMATION.DEFAULT,
    (widgetStateBuilder) => {
      widgetStateBuilder
        .title(t.app.widgets.emailConfirmation.states.confirmEmail())
        .build();
      widgetStateBuilder.useAsInitialViewState().build();
    },
  );

  widgetBuilder.configureWidgetViewState(
    STATES.EMAIL_CONFIRMATION.ERROR,
    (widgetStateBuilder) => {
      widgetStateBuilder
        .title(t.app.widgets.emailConfirmation.states.error())
        .build();
    },
  );

  widgetBuilder.configureWidgetViewState(
    STATES.EMAIL_CONFIRMATION.WARNING,
    (widgetStateBuilder) => {
      widgetStateBuilder
        .title(t.app.widgets.emailConfirmation.states.warning())
        .build();
    },
  );

  widgetBuilder.configureWidgetViewState(
    STATES.EMAIL_CONFIRMATION.CODE_RESEND_TIME,
    (widgetStateBuilder) => {
      widgetStateBuilder
        .title(t.app.widgets.emailConfirmation.states.resendTimer())
        .build();
    },
  );
};
