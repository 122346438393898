import { installScriptBuilder } from '@wix/bob-widget-services';
import forgotPassword from './components/forgot-password/.component.json';
import adminApproval from './components/admin-approval/.component.json';
import emailConfirmation from './components/email-confirmation/.component.json';
import expiredToken from './components/expired-token/.component.json';
import accessRestricted from './components/access-restricted/.component.json';
import resetPassword from './components/reset-password/.component.json';
import { PRESETS, TPA_PAGES } from './constants';
import {
  installWidgetInLightBox,
  installWidgetInPage,
} from './editor-app/widgets';
import { createRouter } from './editor-app/router';
import { ILocaleKeys } from './locale-keys/LocaleKeys';

export const installer = (t: ILocaleKeys) =>
  installScriptBuilder(t.app.adding.authentication.pages())
    .withInitialContext({ pages: {} })
    // .withStep('Cleaning up pages', cleanAllPages)
    .withStep(
      t.app.installation.prepering(),
      async (editorSDK, token, context, appDefId, isStudio, isResponsive) => {
        context.pages.resetPassword = await installWidgetInPage(editorSDK, {
          title: t.app.pages.resetPassword.title(),
          id: resetPassword.id,
          scopedPreset: PRESETS.RESET_PASSWORD,
          tpaPageId: TPA_PAGES.RESET_PASSWORD,
          isStudio,
          isResponsive,
        });

        context.pages.expiredToken = await installWidgetInPage(editorSDK, {
          title: t.app.pages.expiredToken.title(),
          id: expiredToken.id,
          scopedPreset: PRESETS.EXPIRED_TOKEN,
          tpaPageId: TPA_PAGES.EXPIRED_TOKEN,
          isStudio,
          isResponsive,
        });

        context.pages.noPermission = await installWidgetInPage(editorSDK, {
          title: t.app.pages.accessRestricted.title(),
          id: accessRestricted.id,
          scopedPreset: PRESETS.ACCESS_RESTRICTED,
          tpaPageId: TPA_PAGES.ACCESS_RESTRICTED,
          isStudio,
          isResponsive,
        });
      },
    )
    .withStep(
      t.app.installation.configuring(),
      async (editorSDK, token, context, appDefId, isStudio, isResponsive) => {
        context.pages.adminApproval = await installWidgetInLightBox(editorSDK, {
          title: t.app.pages.pendingApproval.title(),
          id: adminApproval.id,
          scopedPreset: PRESETS.ADMIN_APPROVAL,
          tpaPageId: TPA_PAGES.ADMIN_APPROVAL,
          isStudio,
          isResponsive,
          popupRole: 'admin-approval',
          height: 350,
        });
        context.pages.emailConfirmation = await installWidgetInLightBox(
          editorSDK,
          {
            title: t.app.pages.emailConfirmation.title(),
            id: emailConfirmation.id,
            scopedPreset: PRESETS.EMAIL_CONFIRMATION,
            tpaPageId: TPA_PAGES.EMAIL_CONFIRMATION,
            isStudio,
            isResponsive,
            popupRole: 'emailConfirmation',
          },
        );
        context.pages.forgotPassword = await installWidgetInLightBox(
          editorSDK,
          {
            title: t.app.pages.forgotPassword.title(),
            id: forgotPassword.id,
            scopedPreset: PRESETS.FORGOT_PASSWORD,
            tpaPageId: TPA_PAGES.FORGOT_PASSWORD,
            isStudio,
            isResponsive,
            height: 400,
          },
        );
      },
    )
    .withStep(
      t.app.installation.optimazing(),
      async (editorSDK, token, context) => {
        context.router = await createRouter({ prefix: 'auth', editorSDK });
      },
    )
    .withStep(
      t.app.installation.finishing(),
      async (editorSDK, token, context) => {
        await editorSDK.document.routers.pages.connect(token, {
          pageRef: context.pages.expiredToken.pageRef,
          routerRef: context.router,
          pageRoles: ['expired-token'],
          slug: TPA_PAGES.EXPIRED_TOKEN,
        });
        await editorSDK.document.routers.pages.connect(token, {
          pageRef: context.pages.noPermission.pageRef,
          routerRef: context.router,
          pageRoles: ['access-restricted'],
          slug: TPA_PAGES.ACCESS_RESTRICTED,
        });
        await editorSDK.document.routers.pages.connect(token, {
          pageRef: context.pages.resetPassword.pageRef,
          routerRef: context.router,
          pageRoles: ['reset-password'],
          slug: TPA_PAGES.RESET_PASSWORD,
        });
      },
    )
    .build();
