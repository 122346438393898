import {
  EditorSDK,
  PopupPageDefinition,
  ComponentRef,
  PageDefinition,
} from '@wix/platform-editor-sdk';
import { PageDefinitionData } from './editor.app.types';
import { createSectionDefinition } from './definitions/createSectionDefinition';
import { createWidgetDefinition } from './definitions/createWidgetDefinition';
import app from '../../.application.json';
import { PRESETS } from '../constants';
import accessRestricted from '../components/access-restricted/.component.json';
import { ILocaleKeys } from '../locale-keys/LocaleKeys';

const getDefaultPageDefenition = (
  appDefId: string,
  pageDefenitionData: PageDefinitionData,
): Partial<PopupPageDefinition> => {
  const {
    title,
    tpaPageId,
    id,
    isStudio,
    isResponsive,
    scopedPreset,
    replacerComponents,
  } = pageDefenitionData;

  // @ts-expect-error
  return {
    ...(isResponsive && !isStudio
      ? {
          components: [
            createSectionDefinition([
              createWidgetDefinition({
                appDefinitionId: appDefId,
                widgetId: id,
                presetId: scopedPreset.desktop,
                mobilePresetId: scopedPreset.mobile,
              }),
            ]) as any,
          ],
        }
      : undefined),
    ...(replacerComponents ? { components: replacerComponents } : undefined),
    data: {
      hidePage: false,
      indexable: false,
      id: `${tpaPageId}_id`,
      type: 'Page',
      pageUriSEO: title,
      tpaPageId,
      appDefinitionId: appDefId,
      managingAppDefId: appDefId,
    },
    props: {
      type: 'PageProperties',
      desktop: {
        popup: {
          closeOnOverlayClick: false,
        },
      },
      mobile: {
        popup: {
          closeOnOverlayClick: false,
        },
      },
    },
    layout: {
      width: 980,
      height: 500,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  };
};

export const createPage = async (
  editorSDK: EditorSDK,
  pageDefenitionData: PageDefinitionData,
) => {
  const pageRef = await editorSDK.pages.add('', {
    title: pageDefenitionData.title,
    shouldAddMenuItem: false,
    shouldNavigateToPage: false,
    definition: getDefaultPageDefenition(
      app.appDefinitionId,
      pageDefenitionData,
    ),
  });

  const containerRef = await getContainer(
    editorSDK,
    pageRef,
    pageDefenitionData.isStudio,
  );
  return {
    pageRef,
    containerRef,
  };
};

const getContainer = async (
  editorSDK: EditorSDK,
  pageRef: ComponentRef,
  isStudio: boolean,
): Promise<ComponentRef> => {
  const [sectionRef, studioSectionRef] = await editorSDK.components.getChildren(
    '',
    {
      componentRef: pageRef,
    },
  );

  const containerRef = isStudio ? studioSectionRef : sectionRef;

  return containerRef ?? pageRef;
};

export const getReplacerComponents = (
  isStudio: boolean,
  componentName: string,
) => {
  try {
    return require(`../components/${componentName}/replacer/${
      isStudio ? 'stidio' : 'classic'
    }.json`);
  } catch (e) {
    console.log('Error while getting replacer components:', e);
  }
};

export const replacePageWithAppReflow = async (
  editorSDK,
  t: ILocaleKeys,
  pageRef,
  isStudio,
  componentName,
) => {
  try {
    const pageData = await editorSDK.pages.getPageData('', { pageRef });
    const { title, tpaPageId } = pageData;

    const replacerComponents = getReplacerComponents(isStudio, componentName);
    const { pageRef: replacerPageRef } = await createPage(editorSDK, {
      title: `${t.app.settings.replacer.custom()} ${title}`,
      id: accessRestricted.id,
      isStudio: false,
      scopedPreset: PRESETS.ACCESS_RESTRICTED,
      tpaPageId: `${tpaPageId}Replacer`,
      isResponsive: false,
      replacerComponents,
    });
    await editorSDK.editor.routers.setReplacerPage('', {
      pageRef,
      replacerPageRef,
      setAsActive: true,
    });
    // inject Velo code template to the replacer page
    const {
      VeloTemplate,
    } = require(`../components/${componentName}/replacer/veloTemplate`);
    await editorSDK.vfs.writeFile('', {
      path: `public/pages/${replacerPageRef.id}.js`,
      content: VeloTemplate,
    });
  } catch (e) {
    console.log('Error while creating replacer:', e);
  }
};
