import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { GetAppManifestFn, PagesBuilder } from '@wix/yoshi-flow-editor';
import adminApproval from '../components/admin-approval/.component.json';
import emailConfirmation from '../components/email-confirmation/.component.json';
import expiredToken from '../components/expired-token/.component.json';
import forgotPassword from '../components/forgot-password/.component.json';
import accessRestricted from '../components/access-restricted/.component.json';
import resetPassword from '../components/reset-password/.component.json';
import errorMessage from '../components/error-message/.component.json';

import { COMPONENT_NAMES, TPA_PAGES } from '../constants';
import { initLocaleKeys } from '../initLocaleKeys';
import app from '../../.application.json';
import { installLightbox, replaceLightboxWithAppReflow } from './lightbox';
import { ApplicationAction, PageRef } from '@wix/platform-editor-sdk';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import {
  configureForgotPasswordViewStates,
  setForgotPasswordWidgetStageBehavior,
} from '../components/forgot-password/editor.contoller';
import { setExpiredTokenWidgetStageBehavior } from '../components/expired-token/editor.controller';
import { setEmailConfirmationWidgetStageBehavior } from '../components/email-confirmation/editor.controller';
import { setAdminApprovalWidgetStageBehavior } from '../components/admin-approval/editor.controller';
import { setAccessRestrictedWidgetStageBehavior } from '../components/access-restricted/editor.controller';
import {
  configureResetPasswordViewStates,
  setResetPasswordWidgetStageBehavior,
} from '../components/reset-password/editor.controller';
import { setErrorMessageWidgetStageBehavior } from '../components/error-message/editor.controller';
import { replacePageWithAppReflow } from './page';
import { setLightboxGfpp } from '../utils';

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  context,
  flowAPI,
) => {
  const { appManifestBuilder } = options;
  const isResponsive = context.origin.type === 'RESPONSIVE';
  const isStudio = isResponsive || context.origin.subType === 'STUDIO';
  const appPopups = await editorSDK.pages.popupPages.getApplicationPopups('');
  const appPages = await editorSDK.pages.getApplicationPages('');
  const isDevMode = await editorSDK.editor.developerMode.isEnabled('');
  // Fetching blocks editor manifest changes
  const baseManifest = await fetchWidgetsStageData(context.initialAppData);
  console.log('🚀 ~ baseManifest:', baseManifest);
  const t = initLocaleKeys(flowAPI.translations.i18n);

  [...appPages, ...appPopups].forEach((page) => {
    editorSDK.pages.setState('', {
      state: {
        [page.tpaPageId!]: [{ id: page.id } as PageRef],
      },
    });
  });

  const viewSiteMembersAction: ApplicationAction = {
    // @ts-expect-error
    type: 'view_site_members',
  };

  const openSignUpSettingsAction: ApplicationAction = {
    // @ts-expect-error
    type: 'open_signup_settings',
  };

  appManifestBuilder
    .withJsonManifest(baseManifest as any)
    .configurePagesTab((pagesTabBuilder) => {
      return pagesTabBuilder
        .addAction({
          title: t.app.actions.manageMemberAccess(),
          icon: 'settingsAction',
          onClick: () => {
            editorSDK.editor.openDashboardPanel('', {
              url: '/site-members/access',
              closeOtherPanels: false,
            });
          },
        })
        .addAction(viewSiteMembersAction)
        .addAction(openSignUpSettingsAction)
        .setGrouping([
          {
            id: 'resetPassword',
            title: t.app.settings.tabs.group.resetPassword(),

            pages: [...appPopups, ...appPages]
              .filter((page) =>
                filterByIds(page.tpaPageId!, [
                  TPA_PAGES.RESET_PASSWORD,
                  TPA_PAGES.FORGOT_PASSWORD,
                ]),
              )
              .map((page) => page.id!),
          },
          {
            id: 'emailConfirmation',
            title: t.app.settings.tabs.group.emailConfirmation(),

            pages: [...appPopups, ...appPages]
              .filter((page) =>
                filterByIds(page.tpaPageId!, [
                  TPA_PAGES.EMAIL_CONFIRMATION,
                  TPA_PAGES.EXPIRED_TOKEN,
                ]),
              )
              .map((page) => page.id!),
          },
          {
            id: 'permissions',
            title: t.app.settings.tabs.group.permissions(),

            pages: [...appPopups, ...appPages]
              .filter((page) =>
                filterByIds(page.tpaPageId!, [
                  TPA_PAGES.ACCESS_RESTRICTED,
                  TPA_PAGES.ADMIN_APPROVAL,
                ]),
              )
              .map((page) => page.id!),
          },
        ])
        .set({ displayName: t.app.settings.tabs.group.authPages() });
    })
    .configurePages((pagesBuilder) => {
      const configureInternalPage =
        (
          componentName: (typeof COMPONENT_NAMES)[keyof typeof COMPONENT_NAMES],
          tooltip: string,
          isPopup: boolean = false,
        ): Parameters<PagesBuilder['configureState']>[1] =>
        (stateBuilder) => {
          const icon = isPopup ? 'popup' : 'page';
          stateBuilder
            .set({ icon, tooltip })
            .addAction({ type: 'page_rename' });
          if (!isPopup) {
            stateBuilder.addSettingsTab(
              {
                title: t.app.settingsTab.settings.pageInfo(),
                type: 'page_info',
                url: getPanelUrl(componentName, 'PageInfo'),
              },
              { title: t.app.settingsTab.layout.title(), type: 'layout' },
            );
          }
          if (isDevMode && !isStudio) {
            if (isPopup) {
              stateBuilder.addAction({
                title: t.app.settings.lightbox.action.replace(),
                onClick: async (event: any) => {
                  const pageRef = event.detail.pageRef;
                  replaceLightboxWithAppReflow(
                    editorSDK,
                    t,
                    pageRef,
                    isStudio,
                    componentName,
                  );
                },
                icon: 'router_icon',
              });
              return;
            }
            stateBuilder.addAction({
              title: t.app.settings.page.action.replace(),
              onClick: async (event: any) => {
                const pageRef = event.detail.pageRef;
                replacePageWithAppReflow(
                  editorSDK,
                  t,
                  pageRef,
                  isStudio,
                  componentName,
                );
              },
              icon: 'router_icon',
            });
          }
        };

      pagesBuilder.configureState(
        TPA_PAGES.FORGOT_PASSWORD,
        configureInternalPage(
          COMPONENT_NAMES.FORGOT_PASSWORD,
          t.app.widgets.forgotPassword.tooltip(),
          true,
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.ADMIN_APPROVAL,
        configureInternalPage(
          COMPONENT_NAMES.ADMIN_APPROVAL,
          t.app.widgets.pendingApproval.tooltip(),
          true,
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.EMAIL_CONFIRMATION,
        configureInternalPage(
          COMPONENT_NAMES.EMAIL_CONFIRMATION,
          t.app.widgets.emailConfirmation.tooltip(),
          true,
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.RESET_PASSWORD,
        configureInternalPage(
          COMPONENT_NAMES.RESET_PASSWORD,
          t.app.widgets.resetPassword.tooltip(),
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.EXPIRED_TOKEN,
        configureInternalPage(
          COMPONENT_NAMES.EXPIRED_TOKEN,
          t.app.widgets.expiredToken.tooltip(),
        ),
      );
      pagesBuilder.configureState(
        TPA_PAGES.ACCESS_RESTRICTED,
        configureInternalPage(
          COMPONENT_NAMES.ACCESS_RESTRICTED,
          t.app.widgets.accessRestricted.tooltip(),
        ),
      );
    });

  appManifestBuilder.configureWidget(
    forgotPassword.controllerId,
    async (widgetBuilder) => {
      void configureForgotPasswordViewStates(widgetBuilder, t);
      void setForgotPasswordWidgetStageBehavior(widgetBuilder, t);
    },
  );

  appManifestBuilder.configureWidget(
    expiredToken.controllerId,
    (widgetBuilder) => {
      void setExpiredTokenWidgetStageBehavior(widgetBuilder, t);
    },
  );

  appManifestBuilder.configureWidget(
    emailConfirmation.controllerId,
    (widgetBuilder) => {
      void setEmailConfirmationWidgetStageBehavior(widgetBuilder, t);
    },
  );

  appManifestBuilder.configureWidget(
    adminApproval.controllerId,
    (widgetBuilder) => {
      void setAdminApprovalWidgetStageBehavior(widgetBuilder, t);
    },
  );

  appManifestBuilder.configureWidget(
    accessRestricted.controllerId,
    (widgetBuilder) => {
      void setAccessRestrictedWidgetStageBehavior(widgetBuilder, t);
    },
  );

  appManifestBuilder.configureWidget(
    resetPassword.controllerId,
    (widgetBuilder) => {
      void configureResetPasswordViewStates(widgetBuilder, t);
      void setResetPasswordWidgetStageBehavior(widgetBuilder, t);
    },
  );

  appManifestBuilder.configureWidget(
    errorMessage.controllerId,
    (widgetBuilder) => {
      void setErrorMessageWidgetStageBehavior(widgetBuilder, t);
    },
  );

  setLightboxGfpp(appManifestBuilder, TPA_PAGES.ADMIN_APPROVAL);
  setLightboxGfpp(appManifestBuilder, TPA_PAGES.EMAIL_CONFIRMATION);
  setLightboxGfpp(appManifestBuilder, TPA_PAGES.FORGOT_PASSWORD);

  await editorSDK.addEventListener('viewStateChanged', (event) => {
    console.log(event);
  });

  const appManifest = appManifestBuilder.build();
  console.log('🚀 ~ appManifest:', appManifest);
  return appManifest;
};

const filterByIds = (tpaPageId: string, pageIds: string[]) => {
  return pageIds.includes(tpaPageId);
};
