import { EditorSDK, RouterRef } from '@wix/platform-editor-sdk';
import app from '../../.application.json';

type ICreateRouter = ({
  editorSDK,
  prefix,
}: {
  editorSDK: EditorSDK;
  prefix: string;
}) => Promise<RouterRef>;

export const createRouter: ICreateRouter = async ({ editorSDK, prefix }) => {
  const routerRef = await editorSDK.routers.getByPrefix('', {
    prefix,
  });
  if (routerRef) {
    return routerRef;
  }
  const pagesData = await editorSDK.pages.getApplicationPages('');
  const appPopups = await editorSDK.pages.popupPages.getApplicationPopups('');
  const config = {
    slugs: Object.fromEntries(
      pagesData.map((page) => [page.tpaPageId, page.pageUriSEO]),
    ),
    pagesData: {
      ...pagesData,
      ...appPopups,
    },
  };
  return editorSDK.routers.add('', {
    prefix,
    // @ts-expect-error
    appDefinitionId: app.appDefinitionId,
    config,
  });
};
