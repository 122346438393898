import { WidgetBuilder } from '@wix/app-manifest-builder';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { adminApprovalDesignBuilderFn } from './adminApproval.widget.design';
import { getRole } from '../../utils';

export const setAdminApprovalWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName: t.app.widgets.displayNames.adminApproval(),
    nickname: 'adminApproval',
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.app.widgets.gfpp.manageSignupSettings(),
      actionId: CUSTOM_ACTIONS_IDS.MANAGE_SIGNUP_SETTINGS,
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      actionId: CUSTOM_ACTIONS_IDS.ADMIN_APPROVAL_SHOW_HIDE_ELEMENTS,
    })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('hide', { behavior: 'HIDE' })
    .set('animation', { behavior: 'DEFAULT' })
    .set('design', { behavior: 'DEFAULT' })
    .set('animation', { behavior: 'DEFAULT' });

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    essential: {
      enabled: false,
    },
  });

  widgetBuilder.configureWidgetDesign((wb) =>
    adminApprovalDesignBuilderFn(wb, t),
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ADMIN_APPROVAL.TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.pendingApproval.displayNames.title(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ADMIN_APPROVAL.SUB_TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.pendingApproval.displayNames.subtitle(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ADMIN_APPROVAL.PRIMARY_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.pendingApproval.displayNames.primaryButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ADMIN_APPROVAL.DESCRIPTION),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.pendingApproval.displayNames.description(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ADMIN_APPROVAL.CLOSE_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.pendingApproval.displayNames.closeButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ERROR_MESSAGE.TEXT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({})
        .behavior()
        .set({
          closed: {
            selectable: false,
          },
        });
    },
  );
};
