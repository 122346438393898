import { WidgetBuilder } from '@wix/app-manifest-builder';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { getRole } from '../../utils';

export const setErrorMessageWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName: t.app.widgets.errorMessage.design.title(),
    nickname: 'errorMessage',
  });

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ERROR_MESSAGE.ICON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        duplicatable: false,
        toggleShowOnAllPagesEnabled: false,
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.ERROR_MESSAGE.TEXT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        duplicatable: false,
        toggleShowOnAllPagesEnabled: false,
        dataEditOptions: 'TEXT_STYLE_ONLY',
        essential: {
          enabled: true,
        },
      });
      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', { behavior: 'HIDE' })
        .set('mainAction2', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('layout', { behavior: 'HIDE' })
        .set('animation', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' })
        .set('add', {
          behavior: 'HIDE',
        })
        .set('widgetPlugins', { behavior: 'HIDE' })
        .set('filters', { behavior: 'HIDE' })
        .set('focalPoint', { behavior: 'HIDE' })
        .set('crop', { behavior: 'HIDE' })
        .set('stretch', { behavior: 'HIDE' })
        .set('presets', { behavior: 'HIDE' })
        .set('pinMode', { behavior: 'HIDE' })
        .set('upgrade', { behavior: 'HIDE' })

        .set('design', { behavior: 'DEFAULT' });
    },
  );

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: 'Elements',
      actionId: CUSTOM_ACTIONS_IDS.ERROR_MESSAGE_SHOW_HIDE_ELEMENTS,
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      behavior: 'HIDE',
    })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });

  widgetBuilder.behavior().set({
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    closed: {
      selectable: false,
      hideFromHierarchy: true,
    },
  });

  widgetBuilder.configureWidgetDesign((wb) => {
    wb.set({
      title: t.app.widgets.errorMessage.design.title(),
    });

    const widgetDesignTabsBuilder = wb.tabs();
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t.app.widgets.errorMessage.design.text(),
          })
          .groups()
          .set({
            roles: [COMPONENT_IDS.ERROR_MESSAGE.TEXT].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t.app.widgets.errorMessage.design.icon(),
          })
          .groups()
          .set({
            roles: [COMPONENT_IDS.ERROR_MESSAGE.ICON].map(getRole),
          });
      });
  });
};
