import { PageRef } from '@wix/platform-editor-sdk';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import app from '../.application.json';
import type { BehaviorObject } from '@wix/document-services-types';

const getOloPopups = (editorSDK: FlowEditorSDK) =>
  editorSDK.pages.popupPages.getApplicationPopups('', {
    includeUnmanaged: true,
  });

export const disablePopupsAutoOpen = async (editorSDK: FlowEditorSDK) => {
  const pagesData = await editorSDK.pages.data.getAll('');
  const allPageRefs = pagesData.map((page) => ({
    id: page.id,
    type: 'DESKTOP',
  })) as unknown as PageRef[];

  const popups = await getOloPopups(editorSDK);
  const installedPopups = popups
    .filter((popup) => popup.managingAppDefId === app.appDefinitionId)
    .map((popup) => popup.id ?? '');

  await allPageRefs.forEach(async (pageRef) => {
    const behaviors = (await editorSDK.components.behaviors.get('', {
      componentRef: pageRef,
    })) as BehaviorObject[];

    const autoOpenBehaviors = behaviors?.filter(
      (behavior) =>
        behavior.action.name === 'load' &&
        behavior.behavior.name === 'openPopup' &&
        installedPopups.includes(behavior.behavior.targetId),
    );

    autoOpenBehaviors?.forEach(async (behavior) => {
      // eslint-disable-next-line no-console
      console.log(
        'SM authentication - removing auto open behavior: ',
        behavior,
      );

      await editorSDK.components.behaviors.remove('', {
        componentRef: pageRef,
        // @ts-expect-error object type is valid here - Document Management also accepts it: https://github.com/wix-private/document-management/blob/340ef4a8c29ef22503fd3303fa4e60dd775c4362/document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts#L334
        behaviorName: behavior,
      });
    });
  });
};
