export const createWidgetDefinition = ({
  appDefinitionId,
  widgetId,
  presetId,
  mobilePresetId,
}: {
  appDefinitionId: string;
  widgetId: string;
  presetId: string;
  mobilePresetId: string;
}): any => {
  return {
    type: 'RefComponent',
    componentType: 'wysiwyg.viewer.components.RefComponent',
    skin: 'skins.core.InlineSkin',
    layout: undefined,
    components: [],
    layoutResponsive: {
      id: '',
      type: 'LayoutData',
      componentLayouts: [
        {
          type: 'ComponentLayout',
          height: {
            type: 'auto',
          },
          width: {
            type: 'percentage',
            value: 100,
          },
          minWidth: {
            type: 'px',
            value: 500,
          },
          minHeight: {
            type: 'px',
            value: 600,
          },
          hidden: false,
        },
      ],
      containerLayouts: [
        {
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      ],
      itemLayouts: [
        {
          type: 'GridItemLayout',
          gridArea: {
            columnStart: 1,
            columnEnd: 2,
            rowStart: 1,
            rowEnd: 2,
          },
          alignSelf: 'center',
          justifySelf: 'center',
          margins: {
            left: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 0,
            },
          },
        },
      ],
    },
    style: 'ref1',
    activeModes: {},
    data: {
      type: 'WidgetRef',
      appDefinitionId,
      widgetId,
    },
    presets: {
      layout: presetId,
      style: presetId,
      type: 'PresetData',
    },
    scopedPresets: {
      'breakpoints-kc1s7zda': {
        type: 'PresetData',
        layout: mobilePresetId,
        style: mobilePresetId,
      },
    },
  };
};
