import { ComponentRef, EditorSDK, ElementData } from '@wix/platform-editor-sdk';
import { ElementsData } from '../../editor.app.types';

export const openShowHidePanel = (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  elementsData: ElementsData,
) => {
  const getCollapsedRefComponentByRole = async (role: string) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );

    return collapsedRefComponent[0]?.componentRef;
  };

  editorSDK.editor.openElementsPanel('token', {
    widgetRef,
    categoriesData: elementsData.categories,
    elementsData: elementsData.data,
    addComponentHandler: async ({ role }, compRef) => {
      const componentRef =
        compRef || (await getCollapsedRefComponentByRole(role));

      await editorSDK.components.refComponents.expandReferredComponent(
        'token',
        {
          componentRef,
        },
      );

      return editorSDK.application.livePreview.refresh('token', {
        shouldFetchData: false,
        source: 'AFTER_DB_CHANGE',
      });
    },
    removeComponentHandler: async (componentRef) => {
      const type = await editorSDK.components.getType('token', {
        componentRef,
      });
      const compToHide = type.includes('AppWidget')
        ? (
            await editorSDK.components.getAncestors('token', { componentRef })
          )[0]
        : componentRef;

      await editorSDK.components.refComponents.collapseReferredComponent(
        'token',
        {
          componentRef: compToHide,
        },
      );
    },
  });
};
