import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import type { GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { installer } from './appInstaller';
import { CUSTOM_ACTIONS_IDS, STATES } from './constants';
import { getAppManifest as getAppManifestWrapper } from './editor-app/appManifest';
import {
  getAccessRestrictedElementsData,
  getAdminApprovalElementsData,
  getEmailConfirmationElementsData,
  getErrorMessagesElementsData,
  getExpiredTokenElementsData,
  getForgotPasswordElementsLinkStateData,
  getForgotPasswordElementsResetStateData,
  getResetPasswordElementsDataByState,
} from './editor-app/gfpp/panels/elementsData';
import { openShowHidePanel } from './editor-app/gfpp/panels/showHideElements';
import { disablePopupsAutoOpen } from './editor.utils';
import { initLocaleKeys } from './initLocaleKeys';

const DSN = 'https://aa9fa2b0e52c4f068c782c8f69a39057@sentry.wixpress.com/292';
const configuration = { dataCallback: (data: any) => data };

export const editorReady = async (editorSDK, appDefId, options, flowAPI) => {
  const fedOpsLogger = options.monitoring.createFedopsLogger()('sm-blocks-app');
  fedOpsLogger.appLoadStarted();
  const sentryMonitor = options.monitoring.createSentryMonitorForApp(
    DSN,
    configuration,
  );
  const t = initLocaleKeys(flowAPI.translations.i18n);
  try {
    const isResponsive = options.origin.type === 'RESPONSIVE';
    const isStudio = isResponsive || options.origin.subType === 'STUDIO';

    if (options.firstInstall) {
      fedOpsLogger.interactionStarted('install');
      await installer(t)(editorSDK, 'Token', appDefId, isStudio, isResponsive);
      fedOpsLogger.interactionEnded('install');
    }

    await disablePopupsAutoOpen(editorSDK);

    // const publicApi = await editorSDK.document.application.getPublicAPI(
    //   'token',
    //   { appDefinitionId: 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f' },
    // );
    // await publicApi?.addPopupsToAppById?.(appDefId);
    editorSDK.addEventListener('widgetGfppClicked', async (event) => {
      const { id, componentRef } = event.detail;
      switch (id) {
        case CUSTOM_ACTIONS_IDS.MANAGE_PAGE_PERMISSIONS: {
          editorSDK.editor.openDashboardPanel('', {
            url: '/member-permissions/roles/manage-permissions',
            closeOtherPanels: false,
          });
          break;
        }
        case CUSTOM_ACTIONS_IDS.MANAGE_MEMBER_ACCESS: {
          editorSDK.editor.openDashboardPanel('', {
            url: '/site-members/access',
            closeOtherPanels: false,
          });
          break;
        }
        case CUSTOM_ACTIONS_IDS.MANAGE_SIGNUP_SETTINGS: {
          editorSDK.editor.openDashboardPanel('', {
            url: '/member-permissions/signup-login-settings',
            closeOtherPanels: false,
          });
          break;
        }
        case CUSTOM_ACTIONS_IDS.FORGOT_PASSWORD_SHOW_HIDE_ELEMENTS: {
          const state = await editorSDK.editor.widgets.getViewState('token', {
            componentRef,
          });
          const elementsData =
            state === STATES.FORGOT_PASSWORD.EMAIL
              ? getForgotPasswordElementsResetStateData(t)
              : getForgotPasswordElementsLinkStateData(t);
          openShowHidePanel(editorSDK, componentRef, elementsData);
          break;
        }
        case CUSTOM_ACTIONS_IDS.ACCESS_RESTRICTEDS_SHOW_HIDE_ELEMENTS: {
          openShowHidePanel(
            editorSDK,
            componentRef,
            getAccessRestrictedElementsData(t),
          );
          break;
        }
        case CUSTOM_ACTIONS_IDS.RESET_PASSWORD_SHOW_HIDE_ELEMENTS: {
          const state = await editorSDK.editor.widgets.getViewState('token', {
            componentRef,
          });
          const elementsData = getResetPasswordElementsDataByState(state, t);
          openShowHidePanel(editorSDK, componentRef, elementsData);
          break;
        }
        case CUSTOM_ACTIONS_IDS.EMAIL_CONFIRMATION_SHOW_HIDE_ELEMENTS:
          openShowHidePanel(
            editorSDK,
            componentRef,
            getEmailConfirmationElementsData(t),
          );
          break;
        case CUSTOM_ACTIONS_IDS.ADMIN_APPROVAL_SHOW_HIDE_ELEMENTS:
          openShowHidePanel(
            editorSDK,
            componentRef,
            getAdminApprovalElementsData(t),
          );
          break;
        case CUSTOM_ACTIONS_IDS.EXPIRED_TOKEN_SHOW_HIDE_ELEMENTS:
          openShowHidePanel(
            editorSDK,
            componentRef,
            getExpiredTokenElementsData(t),
          );
          break;
        case CUSTOM_ACTIONS_IDS.ERROR_MESSAGE_SHOW_HIDE_ELEMENTS:
          openShowHidePanel(
            editorSDK,
            componentRef,
            getErrorMessagesElementsData(t),
          );
          break;
      }
    });

    const COMPONENT_TYPES_TO_RESTORE = [
      'wixui.StylableButton',
      'wysiwyg.viewer.components.inputs.TextInput',
      'mobile.core.components.Container',
    ];

    editorSDK.addEventListener('componentDeleted', async (event) => {
      console.log('componentDeleted', event);
      const { componentRef } = event.detail;
      const componentType = await editorSDK.components.getType('', {
        componentRef,
      });
      if (!COMPONENT_TYPES_TO_RESTORE.includes(componentType)) {
        return;
      }
      editorSDK.editor.showUserActionNotification('', {
        message: t.app.warning.restoreElementText(),
        type: 'warning',
        link: {
          caption: t.app.warning.restoreElementLink(),
          onClick: async () => {
            await editorSDK.document.components.refComponents.expandReferredComponent(
              '',
              { componentRef },
            );
          },
        },
      });
    });
  } catch (e) {
    sentryMonitor.captureException(e);
  }

  fedOpsLogger.appLoaded();
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
) => {
  return getAppManifestWrapper(options, editorSDK, contextParams, flowAPI);
};
