import { WidgetBuilder } from '@wix/app-manifest-builder';
import { COMPONENT_IDS, CUSTOM_ACTIONS_IDS } from '../../constants';
import { ILocaleKeys } from '../../locale-keys/LocaleKeys';
import { forgotPasswordDesignBuilderFn } from './forgotPassword.widget.design';
import { getRole } from '../../utils';

export const setForgotPasswordWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({
    displayName: t.app.widgets.displayNames.forgotPassword(),
    nickname: 'forgotPassword',
  });

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.app.widgets.gfpp.changeDesign(),
      actionType: 'design',
      actionId: '',
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', {
      actionId: CUSTOM_ACTIONS_IDS.FORGOT_PASSWORD_SHOW_HIDE_ELEMENTS,
    })
    .set('connect', { behavior: 'HIDE' })
    .set('stretch', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' })
    .set('layout', { behavior: 'DEFAULT' });

  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: true,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.EMAIL_INPUT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.forgotPassword.displayNames.emailInput(),
      });
      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', { behavior: 'DEFAULT' });

      connectedComponentsBuilder
        .panel<'TextInput', 'settings'>('settings')
        .configureControls({
          errorMessageFormat: {
            hidden: true,
          },
          textType: {
            hidden: true,
          },
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.CONTAINER),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        canReparent: false,
        pinnable: false,
        toggleShowOnAllPagesEnabled: false,
        resizable: false,
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.forgotPassword.displayNames.title(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUB_TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.forgotPassword.displayNames.subtitle(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.EMAIL_INPUT),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t.app.components.forgotPassword.displayNames.emailInput(),
        showFocusBox: true,
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.forgotPassword.displayNames.primaryButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.CLOSE_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName:
            t.app.components.forgotPassword.displayNames.closeButton(),
        })
        .gfpp()
        .set('link', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .panel<'SiteButton', 'settings'>('settings')
        .configureControls({
          link: {
            hidden: true,
          },
          label: {
            hidden: false,
          },
        });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.forgotPassword.displayNames.linkSent.title(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.SUB_TITLE),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.forgotPassword.displayNames.linkSent.subtitle(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.GOT_IT_BUTTON),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.forgotPassword.displayNames.linkSent.gotIt(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.DIDNT_GET_EMAIL_INFO),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.forgotPassword.displayNames.linkSent.checkSpam(),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    getRole(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR),
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName:
          t.app.components.forgotPassword.displayNames.errorMessages(),
      });
    },
  );

  widgetBuilder.configureWidgetDesign((wb) =>
    forgotPasswordDesignBuilderFn(wb, t),
  );
};

export const configureForgotPasswordViewStates = (
  widgetBuilder: WidgetBuilder,
  t: ILocaleKeys,
): void => {
  widgetBuilder.configureWidgetViewState('reset', (widgetStateBuilder) => {
    widgetStateBuilder.title(t.app.widgets.forgotPassword.states.emailStep());
    widgetStateBuilder.useAsInitialViewState().build();
  });

  widgetBuilder.configureWidgetViewState('link', (widgetStateBuilder) => {
    widgetStateBuilder
      .title(t.app.widgets.forgotPassword.states.linkSent())
      .build();
  });

  widgetBuilder.configureWidgetViewState('error', (widgetStateBuilder) => {
    widgetStateBuilder
      .title(t.app.widgets.forgotPassword.states.error())
      .build();
  });
};
